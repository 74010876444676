
export const blogData = [
    {
        id: 1,
        category: ["CONCEPT"],
        blogType: "grid",
        tags: ["Engineering", "Science", "Deep Tech"],
        img: "/assets/img/picn3.png",
        title: "Our view on Quantum Computing",
        content: "As technology continues to evolve at an exponential pace, quantum computing stands out as one of the...",
        author: 1,
        likes: 28,
        comments: 0,
        date: "18 June 2023",
        fullContent: `
<p>As technology continues to evolve at an exponential pace, quantum computing stands out as one of the most promising frontiers that could revolutionize the digital landscape. At TAEG, a forward-thinking digital consultancy firm, we firmly believe in staying at the forefront of emerging technologies. In this blog post, we delve into our strategic view on quantum computing and how it is poised to reshape industries, drive innovation, and unlock unprecedented computational power.</p>

<h6>Understanding Quantum Computing</h6>

<p>Before we dive into our strategic perspective, let's grasp the fundamental concept of quantum computing. Unlike classical computers, which use bits to represent either a 0 or a 1, quantum computers leverage quantum bits, or qubits, which can exist in multiple states simultaneously through a phenomenon known as superposition. This unique property allows quantum computers to perform intricate calculations exponentially faster than traditional computers, particularly in solving complex problems and optimization tasks.</p>

<h6>Potential Implications for Industries</h6>

<p>Pharmaceuticals and Healthcare: Quantum computing can expedite drug discovery processes by simulating molecular interactions with higher precision, leading to the development of life-saving medications and treatments.</p>

<p>Finance and Risk Management: In the financial sector, quantum computing can enable more accurate risk modeling, portfolio optimization, and fraud detection, empowering institutions to make data-driven decisions in real-time.</p>

<p>Supply Chain and Logistics: Quantum algorithms can optimize supply chain logistics, minimizing transportation costs, and enhancing inventory management, leading to more efficient and sustainable operations.</p>

<p>Artificial Intelligence and Machine Learning: Quantum computing could significantly enhance AI and ML algorithms, enabling breakthroughs in natural language processing, image recognition, and data analysis.</p>

<h6>TAEG's Approach to Quantum Computing</h6>

<p>As a partner at TAEG, I am proud to say that our firm has taken a proactive approach to quantum computing, recognizing its potential to redefine industries and create new opportunities. Our strategic vision revolves around three key pillars:</p>

<p>1. Research and Innovation: We actively invest in quantum computing research, collaborating with leading experts, universities, and research institutes to gain a deeper understanding of the technology's capabilities. By staying informed about the latest advancements, we ensure that our clients benefit from cutting-edge solutions.</p>

<p>2. Integration and Implementation: TAEG focuses on the practical integration of quantum computing into existing business processes. We work closely with our clients to identify use cases that can benefit from quantum computing, gradually incorporating the technology alongside classical computing to maximize efficiency and harness its potential.</p>

<p>3. Talent Development: As quantum computing expertise is scarce, TAEG is committed to nurturing a pool of skilled professionals by supporting education and training initiatives. By fostering talent in this domain, we aim to build a quantum-ready workforce that can drive innovation across industries.</p>

<p>At TAEG, we firmly believe that quantum computing is not merely a futuristic concept but an imminent reality that will redefine the limits of computational power. As a digital consultancy firm, we are excited about the endless possibilities that quantum computing offers to our clients. By staying at the forefront of this quantum revolution, we are determined to enable businesses to leverage this cutting-edge technology and stay ahead of the competition in an increasingly dynamic and interconnected world. Embracing quantum computing is not just a strategic choice; it is an essential step towards shaping a brighter, more innovative future for all.</p>
        `
    },
    {
        id: 2,
        category: ["CONCEPT"],
        blogType: "grid",
        tags: ["Product"],
        img: "/assets/img/digital_brand.png",
        title: "Crafting a Strong Brand Identity: The Strategic Key to Lasting Impact and Recognition",
        content: "In today's fiercely competitive business landscape, establishing a strong brand identity has...",
        author: 2,
        likes: 30,
        comments: 0,
        date: "09 July 2023",
        fullContent: `
<p>In today's fiercely competitive business landscape, establishing a strong brand identity has become more critical than ever. As a partner at TAEG, a leading digital consultancy firm, I am excited to share our strategic view on the importance of crafting a robust brand identity and how it serves as the key to achieving lasting impact and recognition in the marketplace. In this blog post, we will explore the significance of brand identity, its role in building customer trust and loyalty, and how it sets businesses on the path to enduring success.</p>

<h6>Defining Brand Identity</h6>

<p>Before delving into its strategic value, let's understand what brand identity entails. Brand identity encompasses the visual, verbal, and emotional elements that distinguish a company and its offerings from competitors. It includes the logo, color palette, typography, taglines, brand voice, and overall personality that conveys the essence of the brand to consumers.</p>

<h6>Building Recognition and Recall</h6>

<p>At TAEG, we firmly believe that a strong brand identity is the bedrock for building recognition and recall among consumers. A well-crafted logo, for instance, becomes synonymous with the company, instantly triggering brand association in the minds of customers. Consistency across all brand touchpoints, from websites and social media to marketing collateral, reinforces the brand's image, making it easier for customers to recall and identify the company amidst a sea of competitors.</p>

<h6>Fostering Trust and Loyalty</h6>

<p>A strategically designed brand identity fosters trust and loyalty among customers. When a brand consistently delivers on its promises and reflects its values through its identity, consumers are more likely to develop an emotional connection. This emotional bond creates brand loyalty, turning customers into brand advocates who not only make repeat purchases but also recommend the brand to others, expanding its reach organically.</p>

<h6>Setting the Tone for Brand Experience</h6>

<p>A well-defined brand identity sets the tone for the entire brand experience. From the moment a customer interacts with the brand, be it through a website visit or a product package, the brand identity communicates the company's values and personality. This alignment between identity and experience enhances customer satisfaction, leaving a positive impression that resonates long after the initial interaction.</p>

<h6>Differentiation in a Crowded Market</h6>

<p>In today's oversaturated marketplace, businesses face the challenge of standing out amidst fierce competition. A strong brand identity differentiates a company from its rivals, giving it a unique position in the minds of consumers. By clearly communicating its unique value proposition through its identity, a company can attract its target audience and build a distinct brand image that becomes a competitive advantage.</p>

<h6>Enduring Impact and Recognition</h6>

<p>Ultimately, crafting a strong brand identity is the key to achieving lasting impact and recognition. A well-established brand identity enables a company to navigate market fluctuations and changing trends while maintaining a consistent image. As consumer preferences evolve, a strong brand identity allows businesses to adapt without losing their core essence, building a legacy that transcends time and industry shifts.</p>

<p>At TAEG, we firmly believe that crafting a strong brand identity is not just an artistic endeavor, but a strategic imperative for businesses seeking lasting impact and recognition. Brand identity lays the foundation for recognition, fosters trust and loyalty, sets the tone for the brand experience, differentiates a company in a crowded market, and ultimately positions the brand for enduring success. By working with our clients to define and refine their brand identities, we empower them to make a profound and lasting impact on their target audience, achieving recognition that stands the test of time. Embracing the power of brand identity is the key to unlocking the full potential of a business and propelling it towards a future of sustained growth and prominence.</p>
        `
    },
    {
        id: 3,
        category: ["CONCEPT"],
        blogType: "grid",
        tags: ["Ventures"],
        img: "/assets/img/venture_building.png",
        title: "Building Startups that Thrive: The Art and Science of Venture Building.",
        content: "As the entrepreneurial spirit continues to drive innovation and disruption, the process of building...",
        author: 1,
        likes: 23,
        comments: 0,
        date: "12 July 2023",
        fullContent: `
<p>As the entrepreneurial spirit continues to drive innovation and disruption, the process of building startups that thrive has become both an art and a science. At TAEG, a leading digital consultancy firm, we understand the challenges and complexities involved in venture building. In this blog post, as a partner at TAEG, I am excited to share our strategic perspective on the art and science of building startups that not only survive but flourish in today's dynamic business landscape. Let's delve into the key elements and strategies that contribute to the success of startup ventures.</p>

<h6>The Art of Vision and Innovation</h6>

<p>At TAEG, we believe that venture building is an art that begins with a compelling vision and a spirit of innovation. Successful startup founders possess a clear vision of the problem they aim to solve and the impact they want to create. This vision drives their passion and determination, inspiring teams to work relentlessly towards achieving their goals. Moreover, fostering a culture of innovation within the startup nurtures creativity, allowing novel ideas to flourish, and pushing the boundaries of what's possible.</p>

<h6>The Science of Market Research and Analysis</h6>

<p>While the art of vision sets the direction, the science of market research and analysis provides the necessary foundation for startup success. Comprehensive market research allows founders to understand their target audience, competition, and market trends. Armed with data-driven insights, startups can refine their value proposition, identify market gaps, and design products or services that truly meet customer needs. Additionally, leveraging data analytics provides an evidence-based approach to decision-making, minimizing risks and maximizing opportunities.</p>

<h6>Strategic Partnerships and Networking</h6>

<p>Thriving startups understand the importance of collaboration and strategic partnerships. Building a robust network of mentors, investors, industry experts, and potential customers provides startups with invaluable guidance and resources. Partnerships can open doors to new markets, boost credibility, and offer access to talent and funding, all of which are critical elements for startup growth.</p>

<h6>Agile and Iterative Approach</h6>

<p>In the fast-paced startup ecosystem, agility is key. Successful startups embrace an iterative approach, constantly learning from feedback and adapting their strategies accordingly. This agile mindset enables startups to pivot when necessary, seize emerging opportunities, and stay ahead of the curve in an ever-changing business landscape.</p>

<h6>User-Centric Design and Customer Experience</h6>

<p>At TAEG, we emphasize the significance of user-centric design and exceptional customer experience. Startups that prioritize customer needs and preferences in their product development and service delivery gain a competitive advantage. By actively seeking customer feedback and incorporating it into their offerings, startups can create products that resonate with their target audience, driving customer loyalty and brand advocacy.</p>

<h6>Resilience and Persistence</h6>

<p>Above all, building startups that thrive requires resilience and persistence. The entrepreneurial journey is fraught with challenges, setbacks, and uncertainties. Founders who possess the resilience to overcome obstacles and the persistence to stay committed to their vision stand a better chance of achieving long-term success.</p>

<p>Building startups that thrive is a delicate blend of art and science. At TAEG, we recognize the transformative power of a compelling vision, innovation, and an agile approach. Coupled with data-driven market research, strategic partnerships, and a relentless focus on customer experience, startups can position themselves for success. As partners at TAEG, we are committed to empowering aspiring entrepreneurs with the expertise and strategic guidance they need to transform their ideas into thriving startups that make a lasting impact in the ever-evolving business landscape. By nurturing the art and science of venture building, we aspire to contribute to a future rich with innovation, disruption, and enduring startup success.</p>
        `
    },
    {
        id: 4,
        category: ["CONCEPT"],
        blogType: "grid",
        tags: ["Product"],
        img: "/assets/img/product_dev.png",
        title: "Look at usual things with unusual eyes",
        content: "The journey from conceptualizing a product to successfully bringing it to market is...",
        author: 2,
        likes: 35,
        comments: 0,
        date: "23 July 2023",
        fullContent: `
<p>The journey from conceptualizing a product to successfully bringing it to market is a challenging yet rewarding process. As a partner at TAEG, a leading digital consultancy firm, I am thrilled to share our strategic insights on navigating the path of product development excellence. In this blog post, we will explore the key elements that contribute to the success of product development, from ideation to market launch, and how a well-crafted strategy can steer businesses towards achieving excellence in this endeavor.</p>

<h6>Innovation and Ideation</h6>

<p>At TAEG, we firmly believe that product development excellence begins with innovation and ideation. Encouraging creativity and fostering a culture that values ideas from all team members can lead to groundbreaking concepts. A robust ideation process involves analyzing market trends, customer needs, and technological advancements to identify opportunities for innovation that align with the company's vision and goals.</p>

<h6>Market Research and Validation</h6>

<p>A critical aspect of product development excellence lies in conducting comprehensive market research and validation. Understanding the target audience, their pain points, and preferences is crucial for designing a product that meets real-world demands. Gathering feedback through surveys, focus groups, and prototypes enables businesses to iterate and refine their product concept based on real user insights.</p>

<h6>Design Thinking and User-Centricity</h6>

<p>TAEG advocates the principles of design thinking and user-centricity throughout the product development process. Adopting a design thinking approach allows teams to empathize with end-users, define problem statements, ideate solutions, prototype, and test iteratively. By keeping the end-user at the core of the design process, businesses can create products that deliver superior user experiences and foster long-term customer loyalty.</p>

<h6>Agile Development Methodology</h6>

<p>The application of agile development methodology has proven to be instrumental in achieving product development excellence. Breaking down the product development journey into manageable sprints allows teams to respond to changing requirements, address challenges promptly, and deliver incremental value at each stage. The iterative nature of agile development ensures that the product evolves organically, aligning it with market needs and feedback.</p>

<h6>Cross-Functional Collaboration</h6>

<p>Successful product development necessitates seamless cross-functional collaboration. At TAEG, we stress the importance of breaking down silos and fostering collaboration between design, engineering, marketing, and other departments involved in the process. This collaborative approach promotes transparency, enhances communication, and accelerates the speed-to-market.</p>

<h6>Quality Assurance and Testing</h6>

<p>To achieve product development excellence, rigorous quality assurance and testing are imperative. Thoroughly testing the product for functionality, performance, and usability ensures that it meets the highest standards of quality and reliability before launch. Addressing issues early in the development process saves time, resources, and enhances the overall user experience.</p>

<h6>Market Launch and Beyond</h6>

<p>Finally, a successful product launch is only the beginning of the journey. Post-launch monitoring, customer feedback analysis, and continuous improvement are vital for maintaining product relevance and ensuring its long-term success. At TAEG, we emphasize the significance of post-launch support and ongoing refinement to keep the product competitive and aligned with market dynamics.</p>

<p>The path from concept to market is a transformative process that demands excellence at every stage. At TAEG, we believe that product development excellence is achieved through innovation, market research, user-centricity, agile methodology, collaboration, and a commitment to quality. By guiding businesses through this strategic journey, we empower them to create products that not only meet customer needs but also make a lasting impact in the marketplace. From concept to market, navigating the path of product development excellence is an exhilarating pursuit that can shape the future of a business and establish its position as an industry leader.</p>
        `
    },
    {
        id: 5,
        category: ["CONCEPT"],
        blogType: "grid",
        tags: ["Strategy"],
        img: "",
        title: "Developing Strategy: Forging the Path to Sustainable Business Success",
        content: "In the ever-evolving landscape of business, developing a robust and effective strategy is the compass...",
        author: 2,
        likes: 25,
        comments: 0,
        date: "12 July 2023",
        fullContent: `
<p>In the ever-evolving landscape of business, developing a robust and effective strategy is the compass that guides organizations towards sustainable success. As a partner at TAEG, a leading digital consultancy firm, I am thrilled to share our strategic insights on the art and science of developing a winning strategy. In this blog post, we will explore the key elements of strategic development, from understanding the business landscape to executing the strategy, and how a well-crafted approach can pave the way for organizations to achieve their goals and stay ahead in today's dynamic marketplace.</p>

<h6>Assessing the Business Landscape</h6>

<p>At TAEG, we believe that the foundation of developing a successful strategy lies in understanding the business landscape. This involves conducting a comprehensive analysis of internal and external factors, including the company's strengths, weaknesses, opportunities, and threats (SWOT analysis). Gaining insights into market trends, customer behaviors, and competitor strategies provides a clear picture of the current business environment, enabling informed decision-making.</p>

<h6>Defining a Clear Vision and Mission</h6>

<p>A well-crafted strategy begins with a clear vision and mission that define the purpose and direction of the organization. At TAEG, we emphasize the importance of aligning the vision and mission with the company's values and long-term objectives. This unifying force not only inspires employees but also serves as a guiding light in every decision and action taken to achieve the desired outcomes.</p>

<h6>Setting SMART Goals</h6>

<p>Developing a successful strategy requires setting Specific, Measurable, Achievable, Relevant, and Time-bound (SMART) goals. TAEG advocates for clear goal-setting that cascades down from the organizational level to individual departments and teams. SMART goals provide a roadmap for progress, ensuring that everyone is working towards a common vision.</p>

<h6>Analyzing Competitive Advantage</h6>

<p>A key element of strategic development is identifying and nurturing the organization's competitive advantage. TAEG emphasizes the need for businesses to know their unique strengths that set them apart from competitors. Whether it's innovation, cost leadership, superior customer service, or a niche market focus, leveraging and building on these advantages is crucial for long-term success.</p>

<h6>Incorporating Flexibility and Agility</h6>

<p>In a rapidly changing business landscape, the ability to adapt is vital. At TAEG, we encourage organizations to embrace flexibility and agility in their strategic development. Anticipating market shifts, customer preferences, and emerging technologies enables companies to respond swiftly and seize new opportunities, giving them a competitive edge.</p>

<h6>Implementing and Executing the Strategy</h6>

<p>Developing a strategy is just the first step; successful execution is what sets the path to success in motion. TAEG emphasizes the need for a well-defined implementation plan with clear action steps, responsibilities, and timelines. Engaging employees, fostering a culture of accountability, and monitoring progress are essential to ensure the strategy is effectively executed.</p>

<h6>Continuous Evaluation and Improvement</h6>

<p>Strategic development is an ongoing process that requires continuous evaluation and improvement. TAEG advocates for a dynamic approach that involves regular performance reviews, feedback gathering, and data analysis. This data-driven approach enables organizations to identify areas of improvement, make necessary adjustments, and stay on course towards their long-term objectives.</p>

<p>Developing a successful strategy is a multifaceted journey that requires a deep understanding of the business landscape, a clear vision, SMART goal-setting, leveraging competitive advantages, embracing flexibility, and strategic execution. At TAEG, we believe that a well-crafted strategy is the cornerstone of sustainable business success, providing a roadmap for growth and adaptability in an ever-changing world. By guiding organizations through the strategic development process, we empower them to forge a path towards achieving their goals, surpassing competition, and creating a lasting impact in their industries. With the right approach and execution, developing strategy becomes an art and science that can shape the future of businesses and pave the way to sustainable success.</p>
        `
    },
    {
        id: 6,
        category: ["CONCEPT"],
        blogType: "grid",
        tags: ["Product"],
        img: "/assets/img/female.png",
        title: "A Glimpse into How TAEG Builds Products: Blending Creativity, Expertise, and Innovation",
        content: "At TAEG, we take immense pride in our product development process, which is meticulously crafted to ensure excellence...",
        author: 2,
        likes: 35,
        comments: 0,
        date: "05 July 2023",
        fullContent: `
<p>At TAEG, we take immense pride in our product development process, which is meticulously crafted to ensure excellence and customer satisfaction. As a leading digital consultancy firm, we strive to create products that not only meet client expectations but also drive innovation and make a lasting impact in the market. In this blog post, we invite you to take a behind-the-scenes look at how TAEG builds products, revealing the key elements that make our approach unique and effective.</p>

<h6>Understanding Client Needs</h6>

<p>Our product development journey begins with a comprehensive understanding of our client's needs, objectives, and vision. At TAEG, we firmly believe in the importance of active listening and effective communication to grasp the nuances of our client's business. By collaboratively defining project goals and success criteria, we ensure that our product development process is tailor-made to meet specific requirements.</p>

<h6>Market Research and Ideation</h6>

<p>In the rapidly evolving digital landscape, staying ahead of market trends is paramount. At TAEG, we invest significant effort in conducting in-depth market research, competitor analysis, and trend forecasting. Armed with these insights, our teams engage in rigorous brainstorming sessions and ideation workshops to generate innovative concepts that align with market demands and user preferences.</p>

<h6>Design Thinking and User-Centricity</h6>

<p>Design thinking lies at the core of our product development philosophy. With a focus on empathy and user-centricity, we place the end-users at the center of our design process. By understanding their pain points, needs, and aspirations, we create products that resonate with the target audience, fostering exceptional user experiences and ensuring long-term customer loyalty.</p>

<h6>Agile Development Methodology</h6>

<p>TAEG embraces the principles of agility throughout the product development lifecycle. Our teams adopt an agile approach, breaking down complex projects into smaller, manageable sprints. This iterative process allows for continuous feedback, flexibility, and rapid adjustments, ensuring that the final product is not only of superior quality but also aligned with changing market dynamics.</p>

<h6>Cross-Functional Collaboration</h6>

<p>Building exceptional products requires seamless collaboration between diverse teams with varied expertise. At TAEG, we foster cross-functional collaboration, where designers, developers, marketers, and other specialists work in tandem to complement each other's strengths. This collective effort ensures that each aspect of the product is carefully crafted and optimized for success.</p>

<h6>Prototyping and Testing</h6>

<p>Prototyping and testing are integral components of our product development process. At TAEG, we emphasize the importance of creating prototypes that simulate the final product's functionalities and user experience. Through rigorous testing and user feedback, we validate the product's performance, identify potential issues, and fine-tune its design until we achieve excellence.</p>

<h6>Quality Assurance and Launch</h6>

<p>Quality is of utmost importance to us at TAEG. Before any product is launched, it undergoes thorough quality assurance testing to ensure it meets the highest standards of performance, security, and reliability. Only when we are confident that the product is ready for the market do we proceed with the launch, taking care to create a compelling marketing strategy to maximize its impact.</p>

<h6>Continuous Improvement and Support</h6>

<p>At TAEG, our commitment to excellence doesn't end with the product launch. We believe in fostering long-term partnerships with our clients, providing ongoing support, and continuously improving the product based on real-world usage and customer feedback. By staying attentive to evolving needs, we ensure that our products remain relevant and competitive in the ever-changing market.</p>

<p>At TAEG, building products is an art that we master through a blend of creativity, expertise, and innovation. By understanding our clients' needs, conducting extensive market research, embracing design thinking, adopting agile methodologies, fostering collaboration, and prioritizing quality and user-centricity, we forge products that resonate with customers and drive business success. Our commitment to continuous improvement and post-launch support ensures that our products remain at the forefront of innovation, leaving a lasting impact in the digital landscape. With every project we undertake, we aspire to create products that not only meet our clients' expectations but also exceed them, positioning them for long-term success in their respective industries.</p>
        `
    },
    // {
    //     id: 7,
    //     category: ["FURNITURE"],
    //     blogType: "grid",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x560",
    //     title: "Houseful of new furniture doesn't mean lot",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
    //     author: 7,
    //     likes: 20,
    //     comments: 0,
    //     date: "19 October 2020"
    // },
    // {
    //     id: 8,
    //     category: ["BRANDING"],
    //     blogType: "grid",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x560",
    //     title: "The best comfort food will always be greens",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
    //     author: 8,
    //     likes: 28,
    //     comments: 0,
    //     date: "09 September 2020"
    // },
    // {
    //     id: 9,
    //     category: ["TRAVELLING"],
    //     blogType: "grid",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x560",
    //     title: "I love traveling all over the world but it's true",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
    //     author: 9,
    //     likes: 25,
    //     comments: 0,
    //     date: "18 August 2020"
    // },
    // {
    //     id: 10,
    //     category: ["CREATIVE"],
    //     blogType: "grid",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x560",
    //     title: "A true photograph not be explained, words",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
    //     author: 3,
    //     likes: 28,
    //     comments: 0,
    //     date: "26 June 2020"
    // },
    // {
    //     id: 11,
    //     category: ["BUSINESS"],
    //     blogType: "grid",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x560",
    //     title: "Consider every mistake do make as asset",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
    //     author: 9,
    //     likes: 35,
    //     comments: 0,
    //     date: "20 April 2020"
    // },
    // {
    //     id: 12,
    //     category: ["EVENTS"],
    //     blogType: "grid",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x560",
    //     title: "Winners make a habit of facturing positive",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
    //     author: 5,
    //     likes: 22,
    //     comments: 0,
    //     date: "14 March 2020"
    // },
    // {
    //     id: 13,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "The best way to predict the future is to create it",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     category: ["LIFESTYLE", "BUSINESS"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     author: 1,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 14,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "Creativity is nothing but a mind set free",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     category: ["FASHION"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     author: 2,
    //     likes: 25,
    //     comments: 0,
    //     date: "17 June 2020",
    // },
    // {
    //     id: 15,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "Simplicity is the ultimate sophistication",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     category: ["CREATIVE"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 16,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "A company is only as good as the people it keeps",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     category: ["MODERN", "BUSINESS"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     author: 4,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 17,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "Reason and judgment are the qualities of leader",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     category: ["CORPORATE"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     author: 5,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 18,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "Business is combination of war and sport",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     category: ["FINANCE"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     author: 6,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 19,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "Look at usual things with unusual for eyes",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     category: ["BUSINESS"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     author: 7,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 20,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "People ignore design that ignores people",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     category: ["CONCEPT"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     author: 8,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 21,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "Good work for people good design is a language",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     category: ["FURNITURE"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     author: 9,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 22,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "Creativity is only obscure your reference",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     category: ["LIFESTYLE"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     author: 1,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 23,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "All progress takes place for outside comfort zone",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     category: ["DIGITAL"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     author: 2,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 25,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "Everything is designed for things are designed",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     category: ["CREATIVE"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 26,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "Fashion and interior design are one the",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     category: ["Web Design"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     author: 4,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 27,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "The best comfort food will always be greens",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     category: ["SUMMER"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     author: 5,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 28,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "Get in over your head as often an possible",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     category: ["LIFESTYLE"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     author: 6,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 29,
    //     category: ["CREATIVE"],
    //     blogType: "simple",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x970",
    //     title: "Creativity is nothing but a mind set free",
    //     content: "Lorem ipsum is simply dummy text printing...",
    //     author: 9,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 30,
    //     category: ["CONCEPT"],
    //     blogType: "simple",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x970",
    //     title: "Simplicity, wit, and good typography",
    //     content: "Lorem ipsum is simply dummy text printing...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 31,
    //     category: ["BUSINESS"],
    //     blogType: "simple",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x970",
    //     title: "Look at usual things with unusual",
    //     content: "Lorem ipsum is simply dummy text printing...",
    //     author: 4,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 32,
    //     category: ["LIFESTYLE"],
    //     blogType: "simple",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x970",
    //     title: "Do not seek praise seek criticism",
    //     content: "Lorem ipsum is simply dummy text printing...",
    //     author: 2,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 33,
    //     category: ["MODERN"],
    //     blogType: "simple",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x970",
    //     title: "Make it simple, but significant",
    //     content: "Lorem ipsum is simply dummy text printing...",
    //     author: 2,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 34,
    //     category: ["FURNITURE"],
    //     blogType: "simple",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x970",
    //     title: "Leave it better than you found it",
    //     content: "Lorem ipsum is simply dummy text printing...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 35,
    //     category: ["DESIGN"],
    //     blogType: "simple",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x970",
    //     title: "Everything is design are designed well",
    //     content: "Lorem ipsum is simply dummy text printing...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 36,
    //     category: ["FASHION"],
    //     blogType: "simple",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x970",
    //     title: "Fashion fades, only style remains same",
    //     content: "Lorem ipsum is simply dummy text printing...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 37,
    //     category: ["MODERN"],
    //     blogType: "simple",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x970",
    //     title: "Never give in except convictions sense",
    //     content: "Lorem ipsum is simply dummy text printing...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 38,
    //     img: "https://via.placeholder.com/800x580",
    //     title: "Recognizing the need is the primary condition design",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 1,
    //     category: ["FASHION"],
    //     blogType: "masonry",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     likes: 28,
    //     comments: 52,
    //     date: "24 February 2020",
    // },
    // {
    //     id: 39,
    //     img: "https://via.placeholder.com/800x850",
    //     title: "Computers are to design as microwaves are to cooking",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 2,
    //     category: ["LIFESTYLE"],
    //     blogType: "masonry",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     likes: 28,
    //     comments: 40,
    //     date: "10 February 2020",
    // },
    // {
    //     id: 40,
    //     img: "https://via.placeholder.com/800x580",
    //     title: "Fashion and interior design are one and the same",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 3,
    //     category: ["DESIGN"],
    //     blogType: "masonry",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     likes: 30,
    //     comments: 42,
    //     date: "18 January 2020",
    // },
    // {
    //     id: 41,
    //     img: "https://via.placeholder.com/800x850",
    //     title: "Opportunities don't happen. You create them",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 4,
    //     category: ["TRAVEL"],
    //     blogType: "masonry",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     likes: 18,
    //     comments: 62,
    //     date: "15 December 2019",
    // },
    // {
    //     id: 42,
    //     img: "https://via.placeholder.com/800x850",
    //     title: "Never give in except to convictions good sense",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 5,
    //     category: ["MODERN"],
    //     blogType: "masonry",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     likes: 22,
    //     comments: 38,
    //     date: "12 November 2019",
    // },
    // {
    //     id: 43,
    //     img: "https://via.placeholder.com/800x850",
    //     title: "An economist's guess is liable good as anybody",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 6,
    //     category: ["FOOD"],
    //     blogType: "masonry",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     likes: 12,
    //     comments: 52,
    //     date: "18 January 2020",
    // },
    // {
    //     id: 44,
    //     img: "https://via.placeholder.com/800x850",
    //     title: "All progress takes place outside the comfort zone",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 7,
    //     category: ["EVENTS"],
    //     blogType: "masonry",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     likes: 28,
    //     comments: 40,
    //     date: "22 November 2019",
    // },
    // {
    //     id: 45,
    //     img: "https://via.placeholder.com/800x850",
    //     title: "Reason and judgment are the qualities of a leader",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 8,
    //     category: ["FASHION"],
    //     blogType: "masonry",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     likes: 25,
    //     comments: 45,
    //     date: "16 October 2019",
    // },
    // {
    //     id: 46,
    //     img: "https://via.placeholder.com/800x850",
    //     title: "A company is only as good as the people it keeps",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 9,
    //     category: ["FURNITURE"],
    //     blogType: "masonry",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     likes: 28,
    //     comments: 50,
    //     date: "20 September 2019",
    // },
    // {
    //     id: 47,
    //     img: "https://via.placeholder.com/800x850",
    //     title: "People ignore designs that ignore people",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 1,
    //     category: ["LIFESTYLE"],
    //     blogType: "masonry",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     likes: 32,
    //     comments: 42,
    //     date: "26 August 2019",
    // },
    // {
    //     id: 48,
    //     img: "https://via.placeholder.com/800x580",
    //     title: "Simplicity is the ultimate sophistication",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 2,
    //     category: ["CONCEPT"],
    //     blogType: "masonry",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     likes: 36,
    //     comments: 52,
    //     date: "20 September 2019",
    // },
    // {
    //     id: 49,
    //     img: "https://via.placeholder.com/800x580",
    //     title: "A true photograph not be explained, words",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 3,
    //     category: ["MODERN"],
    //     blogType: "masonry",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     likes: 28,
    //     comments: 40,
    //     date: "20 June 2019",
    // },
    // {
    //     id: 50,
    //     category: ["CREATIVE"],
    //     blogType: "sideImage",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/1200x840",
    //     title: "Recognizing the need the primary condition design",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 7,
    //     likes: 25,
    //     comments: 0,
    //     date: "18 MARCH 2020",

    // },
    // {
    //     id: 51,
    //     category: ["BUSINESS"],
    //     blogType: "sideImage",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/1200x840",
    //     title: "Good design is obvious great design transparent",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 7,
    //     likes: 25,
    //     comments: 0,
    //     date: "25 FEBRUARY 2020",

    // },
    // {
    //     id: 52,
    //     category: ["MODERN"],
    //     blogType: "sideImage",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/1200x840",
    //     title: "Any product that needs manual to work is broken",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 7,
    //     likes: 25,
    //     comments: 0,
    //     date: "30 JANUARY 2020",

    // },
    // {
    //     id: 53,
    //     category: ["LIFESTYLE"],
    //     blogType: "sideImage",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/1200x840",
    //     title: "Everything is designed for things are the designed",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 7,
    //     likes: 25,
    //     comments: 0,
    //     date: "23 DECEMBER 2019",

    // },
    // {
    //     id: 54,
    //     category: ["FURNITURE"],
    //     blogType: "sideImage",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/1200x840",
    //     title: "Houseful of new furniture doesn't mean lot modern",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 7,
    //     likes: 25,
    //     comments: 0,
    //     date: "20 OCTOBER 2019",

    // },
    // {
    //     id: 55,
    //     category: ["FINANCE"],
    //     blogType: "sideImage",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/1200x840",
    //     title: "Consider every mistake for you do make as an asset",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 7,
    //     likes: 25,
    //     comments: 0,
    //     date: "18 FEBRUARY 2020",

    // },
    // {
    //     id: 56,
    //     category: ["ARCHITECTURE"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/940x940",
    //     title: "Architects design houses. I live in a home",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "05 MARCH 2020",
    //     double_col: true
    // },
    // {
    //     id: 57,
    //     category: ["CREATIVITY"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x800",
    //     title: "Good design doesn't date bad design does",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "28 FEBRUARY 2020",
    //     double_col: false
    // },
    // {
    //     id: 58,
    //     category: ["MODERN"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x800",
    //     title: "Bad design is smoke, while good design",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "20 JANUARY 2020",
    //     double_col: false
    // },
    // {
    //     id: 59,
    //     category: ["BUSINESS"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/940x940",
    //     title: "Winners make a habit of facturing positive",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "15 DECEMBER 2019",
    //     double_col: true
    // },
    // {
    //     id: 60,
    //     category: ["LIFESTYLE"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x800",
    //     title: "Everything designed things are designed well",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "22 NOVEMBER 2019",
    //     double_col: false
    // },
    // {
    //     id: 61,
    //     category: ["FASHION"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x800",
    //     title: "Consider every mistake you do make as asset",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "12 OCTOBER 2019",
    //     double_col: false
    // },
    // {
    //     id: 62,
    //     category: ["CONCEPT"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x800",
    //     title: "People ignore design that ignores people",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "10 SEPTEMBER 2019",
    //     double_col: false
    // },
    // {
    //     id: 63,
    //     category: ["DESIGN"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x800",
    //     title: "Everything designed things are designed well",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "28 AUGUST 2019",
    //     double_col: false
    // },
    // {
    //     id: 64,
    //     category: ["LIFESTYLE"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x800",
    //     title: "Simplicity is the ultimate sophistication",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "22 JUNE 2019",
    //     double_col: false
    // },
    // {
    //     id: 65,
    //     category: ["CREATIVITY"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x800",
    //     title: "Reason and judgment are the qualities of a leader",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "30 APRIL 2019",
    //     double_col: false
    // },
    // {
    //     id: 66,
    //     category: ["fashion"],
    //     blogType: "clean",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/850x885",
    //     title: "Build perfect websites",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "23 FEBRUARY 2020",

    // },
    // {
    //     id: 67,
    //     category: ["travel"],
    //     blogType: "clean",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/850x885",
    //     title: "Beautiful layouts design",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "18 FEBRUARY 2020",

    // },
    // {
    //     id: 68,
    //     category: ["fashion", "travel"],
    //     blogType: "clean",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/850x885",
    //     title: "Online website builder",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "10 JANUARY 2020",

    // },
    // {
    //     id: 69,
    //     category: ["travel", "Lifestyle"],
    //     blogType: "clean",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/850x885",
    //     title: "Different layout type",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "30 DECEMBER 2019",

    // },
    // {
    //     id: 70,
    //     category: ["fashion"],
    //     blogType: "clean",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/850x885",
    //     title: "Elegant elements design",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "22 NOVEMBER 2019",

    // },
    // {
    //     id: 71,
    //     category: ["creative"],
    //     blogType: "clean",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/850x885",
    //     title: "The best comfort music",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "28 OCTOBER 2019",

    // },
    // {
    //     id: 72,
    //     category: ["fashion", "Business"],
    //     blogType: "clean",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/850x885",
    //     title: "Consider every mistake",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "23 SEPTEMBER 2019",

    // },
    // {
    //     id: 73,
    //     category: ["travel", "Business"],
    //     blogType: "clean",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/850x885",
    //     title: "Creativity is only obscure",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "05 AUGUST 2019",

    // },
    // {
    //     id: 74,
    //     category: ["Lifestyle"],
    //     blogType: "clean",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/850x885",
    //     title: "The best way to predict",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "27 APRIL 2019",

    // },
    // {
    //     id: 75,
    //     category: ["Business"],
    //     blogType: "clean",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/850x885",
    //     title: "Never give in except",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "15 MARCH 2019",

    // },
    // {
    //     id: 76,
    //     category: ["Lifestyle"],
    //     blogType: "clean",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/850x885",
    //     title: "An economist's guess",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "09 FEBRUARY 2019",

    // },
    // {
    //     id: 77,
    //     category: ["Lifestyle", "Business"],
    //     blogType: "clean",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/850x885",
    //     title: "Look at usual things",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "23 JANUARY 2019",

    // },
    // {
    //     id: 78,
    //     category: ["CREATIVE"],
    //     blogType: "widget",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/480x300",
    //     title: "Tools i use to learn, work and travel...",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     comments: 0,
    //     date: "11 February 2019"
    // },
    // {
    //     id: 79,
    //     category: ["CONCEPT"],
    //     blogType: "widget",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/480x300",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     title: "Without good architecture design it’s easy...",
    //     author: 3,
    //     comments: 0,
    //     date: "15 February 2019"
    // },
    // {
    //     id: 80,
    //     category: ["EVENTS"],
    //     blogType: "widget",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/480x300",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     title: "Everybody can be great because...",
    //     author: 3,
    //     comments: 0,
    //     date: "25 February 2019"
    // },
    // {
    //     id: 81,
    //     category: ["LIFESTYLE"],
    //     blogType: "widget",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/480x300",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     title: "Styles come and go design is simple...",
    //     author: 3,
    //     comments: 0,
    //     date: "23 January 2019"
    // },
    // {
    //     id: 82,
    //     category: ["NATURE"],
    //     blogType: "widget",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/480x300",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     title: "Alone we can do so little together...",
    //     author: 3,
    //     comments: 0,
    //     date: "19 December 2019"
    // },
    // {
    //     id: 83,
    //     category: ["MOUNTAINS"],
    //     blogType: "widget",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/480x300",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     title: "Design is inherently best optimistic power...",
    //     author: 3,
    //     comments: 0,
    //     date: "18 January 2019"
    // },
    // {
    //     id: 84,
    //     category: ["FINANCE"],
    //     blogType: "widget",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/480x300",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     title: "People ignore design that ignores...",
    //     author: 3,
    //     comments: 0,
    //     date: "11 April 2019"
    // },
    // {
    //     id: 85,
    //     category: ["BUSINESS"],
    //     blogType: "widget",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/480x300",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     title: "A true photograph not be explained...",
    //     author: 3,
    //     comments: 0,
    //     date: "25 March 2019"
    // },
    // {
    //     id: 86,
    //     category: ["LIFESTYLE"],
    //     blogType: "widget",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/480x300",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     title: "Everything creative design things...",
    //     author: 3,
    //     comments: 0,
    //     date: "19 June 2019"
    // },
    // {
    //     id: 87,
    //     category: ["FASHION"],
    //     blogType: "widget",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/480x300",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     title: "Consider every mistake do make...",
    //     author: 3,
    //     comments: 0,
    //     date: "30 August 2019"
    // },
    // {
    //     id: 88,
    //     category: ["FURNITURE"],
    //     blogType: "widget",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/480x300",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     title: "Simplicity is the ultimate sophistic...",
    //     author: 3,
    //     comments: 0,
    //     date: "12 June 2019"
    // },
    // {
    //     id: 89,
    //     category: ["MODERN"],
    //     blogType: "widget",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/480x300",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     title: "The best comfort food will always...",
    //     author: 3,
    //     comments: 0,
    //     date: "18 January 2019"
    // },
    // {
    //     id: 90,
    //     category: ["CREATIVE"],
    //     blogType: "overlayImage",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x710",
    //     title: "DESIGN CAN SPEAK THE IN TONGUE OF ART FORCE OF COMMERCE",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "18 MAY 2020",

    // },
    // {
    //     id: 91,
    //     category: ["CONCEPT"],
    //     blogType: "overlayImage",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x710",
    //     title: "SIMPLICITY IS PROCESS THE OBVIOUS AND ADDING MEANINGFUL",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "22 JUN 2020",

    // },
    // {
    //     id: 92,
    //     category: ["MODERN"],
    //     blogType: "overlayImage",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x710",
    //     title: "EVERYTHING KNOW ABOUT MIDI SKIRTS AND HOW TO WEAR FASHION",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "05 AUG 2020",

    // },
    // {
    //     id: 93,
    //     category: ["FURNITURE"],
    //     blogType: "overlayImage",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x710",
    //     title: "RECOGNIZING THE NEED IS THE PRIMARY CONDITION FOR DESIGN",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "15 APR 2020",

    // },
    // {
    //     id: 94,
    //     category: ["FASHION"],
    //     blogType: "overlayImage",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x710",
    //     title: "FASHION IS SOMETHING BARBAROUS ON FOR IT INNOVATION",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "28 MAR 2020",

    // },
    // {
    //     id: 95,
    //     category: ["LIFESTYLE"],
    //     blogType: "overlayImage",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x710",
    //     title: "RAPPERS WEAR DIAMONDS TO COMPENSATE FOR THE FASHION SENSE",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "18 FEB 2020",

    // },
    // {
    //     id: 96,
    //     category: ["BUSINESS"],
    //     blogType: "overlayImage",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x710",
    //     title: "THE DETAILS ARE NOT THE DETAILS. THEY MAKE THE BEST DESIGN",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "15 JAN 2020",

    // },
    // {
    //     id: 97,
    //     category: ["FINANCE"],
    //     blogType: "overlayImage",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x710",
    //     title: "Creativity is only as obscure as the your  reference",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "26 DEC 2019",

    // },
    // {
    //     id: 98,
    //     category: ["MOUNTAINS"],
    //     blogType: "overlayImage",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x710",
    //     title: "ANY PRODUCT THAT NEEDS A MANUAL TO HARD WORK IS BROKEN",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "09 NOV 2019",

    // },
    // {
    //     id: 99,
    //     category: ["NATURE"],
    //     blogType: "overlayImage",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x710",
    //     title: "REASON AND JUDGMENT ARE THE QUALITIES OF A GOOD LEADER",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "30 OCT 2019",

    // },
    // {
    //     id: 100,
    //     category: ["CREATIVE"],
    //     blogType: "overlayImage",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x710",
    //     title: "GOOD DESIGN IS OBVIOUS. GREAT DESIGN IS MORE TRANSPARENT",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "16 OCT 2019",

    // },
    // {
    //     id: 101,
    //     category: ["EVENTS"],
    //     blogType: "overlayImage",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x710",
    //     title: "ALL PROGRESS TAKES PLACE OUTSIDE OF THE COMFORT ZONE",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "25 SEP 2019",

    // },
    // {
    //     id: 102,
    //     category: ["LIFESTYLE"],
    //     blogType: "modern",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x1010",
    //     title: "How to build branded photography studios",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "18 MAY 2020",

    // },
    // {
    //     id: 103,
    //     category: ["FASHION"],
    //     blogType: "modern",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x1010",
    //     title: "Many things difficult to design prove easy",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "30 APRIL 2020",

    // },
    // {
    //     id: 104,
    //     category: ["CREATIVE"],
    //     blogType: "modern",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x1010",
    //     title: "The proper study of the science of design",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "23 MARCH 2020",

    // },
    // {
    //     id: 105,
    //     category: ["MODERN"],
    //     blogType: "modern",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x1010",
    //     title: "Creativity is nothing but a mind set free",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "15 FEBRUARY 2020",

    // },
    // {
    //     id: 106,
    //     category: ["CORPORATE"],
    //     blogType: "modern",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x1010",
    //     title: "Leave it better than you more found it",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "05 JANUARY 2020",

    // },
    // {
    //     id: 107,
    //     category: ["FINANCE"],
    //     blogType: "modern",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x1010",
    //     title: "Do one thing a every day that scares you",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "24 DECEMBER 2019",

    // },
    // {
    //     id: 108,
    //     category: ["BUSINESS"],
    //     blogType: "modern",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x1010",
    //     title: "A company is only as good as the people",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "16 NOVEMBER 2019",

    // },
    // {
    //     id: 109,
    //     category: ["CONCEPT"],
    //     blogType: "modern",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x1010",
    //     title: "Do not seek praise. Seek the criticism",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "22 OCTOBER 2019",

    // },
    // {
    //     id: 110,
    //     category: ["FURNITURE"],
    //     blogType: "modern",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x1010",
    //     title: "If you do it right, it will the last forever",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "08 SEPTEMBER 2019",

    // },
    // {
    //     id: 111,
    //     category: ["LIFESTYLE"],
    //     blogType: "modern",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x1010",
    //     title: "Judgment are the an qualities of leader",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "18 APRIL 2019",

    // },
    // {
    //     id: 112,
    //     category: ["DIGITAL"],
    //     blogType: "modern",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x1010",
    //     title: "Simplicity, cognition, and good typography",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "29 AUGUST 2019",

    // },
    // {
    //     id: 113,
    //     category: ["CREATIVE"],
    //     blogType: "modern",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x1010",
    //     title: "Everything should be made as simple",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "30 OCT 2019",

    // },
    // {
    //     id: 114,
    //     category: ["Web Design", "drink"],
    //     blogType: "standard",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x563",
    //     title: "Make business easy with beautiful application download store",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, unknown printer took a galley of type...",
    //     author: 3,
    //     likes: 25,
    //     comments: 23,
    //     date: "05 September 2018",
    // },
    // {
    //     id: 115,
    //     category: ["Web Design"],
    //     blogType: "standard",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x563",
    //     title: "Change your life today. Don't gamble on the future, act now, without delay.",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, unknown printer took a galley of type...",
    //     author: 3,
    //     likes: 25,
    //     comments: 23,
    //     date: "05 September 2018",
    // },
    // {
    //     id: 116,
    //     category: ["Web Design", "summer"],
    //     blogType: "standard",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x563",
    //     title: "The time to be happy is now, and the place to be happy is here.",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, unknown printer took a galley of type...",
    //     author: 3,
    //     likes: 25,
    //     comments: 23,
    //     date: "05 September 2018",
    // },
    // {
    //     id: 117,
    //     category: ["Web Design", "coffee"],
    //     blogType: "standard",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x563",
    //     title: "Whenever I think of the past, it brings back so many memories.",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, unknown printer took a galley of type...",
    //     author: 3,
    //     likes: 25,
    //     comments: 23,
    //     date: "05 September 2018",
    // },
    // {
    //     id: 118,
    //     category: ["Architecture"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x1190",
    //     title: "Architects design houses. I live in a home",
    //     content: "",
    //     author: 1,
    //     likes: 0,
    //     comments: 0,
    //     date: "05 March 2020",
    // },
    // {
    //     id: 119,
    //     category: ["Creativity"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x1190",
    //     title: "Good design doesn't date bad design does",
    //     content: "",
    //     author: 1,
    //     likes: 0,
    //     comments: 0,
    //     date: "28 February 2020",
    // },
    // {
    //     id: 120,
    //     category: ["Garnett"],
    //     title: "Fashion and interior design are same",
    //     img: "https://via.placeholder.com/250x250",
    //     blogType: "lifestyle",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, unknown printer took a galley of type...",
    //     author: 3,
    //     likes: 0,
    //     comments: 0,
    //     date: "",
    // },
    // {
    //     id: 121,
    //     category: ["Garnett"],
    //     title: "Everything designed things are designed",
    //     img: "/assets/img/webp/spa-image-05.webp",
    //     blogType: "lifestyle",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, unknown printer took a galley of type...",
    //     author: 1,
    //     likes: 0,
    //     comments: 0,
    //     date: "",
    // },
    // {
    //     id: 122,
    //     category: ["Magistre"],
    //     title: "Consider every mistake do make",
    //     img: "https://via.placeholder.com/250x250",
    //     blogType: "lifestyle",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, unknown printer took a galley of type...",
    //     author: 2,
    //     likes: 0,
    //     comments: 0,
    //     date: "",
    // },
    // {
    //     id: 123,
    //     img: "https://via.placeholder.com/800x580",
    //     title: "Recognizing the need is the primary condition design",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 1,
    //     category: ["FASHION"],
    //     blogType: "masonry",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     likes: 28,
    //     comments: 52,
    //     date: "24 February 2020",
    // },
    // {
    //     id: 124,
    //     img: "https://via.placeholder.com/800x580",
    //     title: "Computers are to design as microwaves are to cooking",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 1,
    //     category: ["LIFESTYLE", "BUSINESS"],
    //     blogType: "masonry",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     likes: 28,
    //     comments: 40,
    //     date: "10 February 2020",
    // },
    // {
    //     id: 125,
    //     img: "https://via.placeholder.com/720x522",
    //     title: "Recognizing the need is the primary condition design",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 1,
    //     category: ["FASHION", "startup"],
    //     blogType: "masonry",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     likes: 28,
    //     comments: 52,
    //     date: "24 February 2020",
    // },
    // {
    //     id: 126,
    //     img: "https://via.placeholder.com/720x522",
    //     title: "Computers are to design as microwaves are to cooking ",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 1,
    //     category: ["LIFESTYLE", "startup"],
    //     blogType: "masonry",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     likes: 28,
    //     comments: 40,
    //     date: "10 February 2020",
    // },
    // {
    //     id: 126,
    //     img: "https://via.placeholder.com/720x522",
    //     title: "Fashion and interior design are one and the same",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 1,
    //     category: ["DESIGN", "startup"],
    //     blogType: "masonry",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     likes: 30,
    //     comments: 42,
    //     date: "18 January 2020",
    // },
    // {
    //     id: 127,
    //     img: "https://via.placeholder.com/526x368",
    //     title: "The best way to predict the future is to create it",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     author: 1,
    //     category: ["DESIGN", "business", "finance", "webagency", "furnitureshop", "fashionshop"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     likes: 30,
    //     comments: 42,
    //     date: "18 January 2020",
    // },
    // {
    //     id: 128,
    //     img: "https://via.placeholder.com/526x368",
    //     title: "Winners make a habit of facturing positive",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     author: 1,
    //     category: ["DESIGN", "business", "finance", "webagency", "furnitureshop", "fashionshop"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     likes: 30,
    //     comments: 42,
    //     date: "18 January 2020",
    // },
    // {
    //     id: 129,
    //     img: "https://via.placeholder.com/526x368",
    //     title: "Computers are to design as microwaves are to cooking",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     author: 1,
    //     category: ["DESIGN", "business", "finance", "webagency", "furnitureshop", "fashionshop"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     likes: 30,
    //     comments: 42,
    //     date: "18 January 2020",
    // },
    // {
    //     id: 130,
    //     img: "https://via.placeholder.com/526x368",
    //     title: "A company is only as good as the people it keeps",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     author: 1,
    //     category: ["DESIGN", "business", "finance", "webagency", "furnitureshop", "fashionshop"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     likes: 30,
    //     comments: 42,
    //     date: "18 January 2020",
    // },
    // {
    //     id: 131,
    //     category: ["fashion", "consulting"],
    //     blogType: "clean",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/361x289",
    //     title: "Incorporating newest trends in digital marketing",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "22 FEBRUARY 2020",
    // },
    // {
    //     id: 132,
    //     category: ["fashion", "consulting"],
    //     blogType: "clean",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/361x289",
    //     title: "Corporate workplace ethics that makes a difference",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "20 FEBRUARY 2020",
    // },
    // {
    //     id: 133,
    //     category: ["fashion", "consulting"],
    //     blogType: "clean",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/361x289",
    //     title: "We take pride in delivering Intelligent and engaging",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "18 FEBRUARY 2020",
    // },
    // {
    //     id: 134,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "The best way to predict the future is to create it",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     category: ["LIFESTYLE", "conference"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     author: 1,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 134,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "The best way to predict the future is to create it",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     category: ["LIFESTYLE", "conference"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     author: 1,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 135,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "Winners make a habit of facturing positive",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     category: ["LIFESTYLE", "conference"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     author: 1,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 136,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "Computers are to design as microwaves are to cooking",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     category: ["LIFESTYLE", "conference"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     author: 1,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 137,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "A company is only as good as the people it keeps",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     category: ["LIFESTYLE", "conference"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     author: 1,
    //     likes: 25,
    //     comments: 0,
    //     date: "13 May 2020",
    // },
    // {
    //     id: 138,
    //     category: ["Creativity", "architecture"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x800",
    //     title: "Bad design is smoke, while good design is a mirror",
    //     content: "",
    //     author: 1,
    //     likes: 0,
    //     comments: 0,
    //     date: "30 AUGUST 2018"
    // },
    // {
    //     id: 139,
    //     category: ["Design", "architecture", "creativeagency"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x800",
    //     title: "Everything designed things are designed well",
    //     content: "",
    //     author: 1,
    //     likes: 0,
    //     comments: 0,
    //     date: "30 AUGUST 2018"
    // },
    // {
    //     id: 140,
    //     category: ["Architecture", "architecture"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x800",
    //     title: "People ignore design that ignores people",
    //     content: "",
    //     author: 1,
    //     likes: 0,
    //     comments: 0,
    //     date: "30 AUGUST 2018"
    // },
    // {
    //     id: 141,
    //     category: ["Interior", "architecture", "creativeagency"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x800",
    //     title: "Reason and judgment are the qualities of a leader",
    //     content: "",
    //     author: 1,
    //     likes: 0,
    //     comments: 0,
    //     date: "30 AUGUST 2018"
    // },
    // {
    //     id: 142,
    //     category: ["Interior", "travelagency", "brandingagency"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x560",
    //     title: "The best way to predict the future is to create it",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     author: 1,
    //     likes: 0,
    //     comments: 0,
    //     date: "30 AUGUST 2018"
    // },
    // {
    //     id: 143,
    //     category: ["Interior", "travelagency", "brandingagency"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x560",
    //     title: "Tour packages are the easiest and most efficient",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     author: 1,
    //     likes: 0,
    //     comments: 0,
    //     date: "30 AUGUST 2018"
    // },
    // {
    //     id: 143,
    //     category: ["Interior", "travelagency", "brandingagency"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x560",
    //     title: "Computers are to design as microwaves are to cooking",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     author: 1,
    //     likes: 0,
    //     comments: 0,
    //     date: "30 AUGUST 2018"
    // },
    // {
    //     id: 144,
    //     category: ["Interior", "travelagency", "brandingagency"],
    //     blogType: "classic",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x560",
    //     title: "A company is only as good as the people it keeps",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum has dummy...",
    //     author: 1,
    //     likes: 0,
    //     comments: 0,
    //     date: "30 AUGUST 2018"
    // },
    // {
    //     id: 145,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "Simple wall decor inspiration saturdays style",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 3,
    //     category: ["FURNITURE", "decor"],
    //     blogType: "masonry",
    //     likes: 28,
    //     comments: 52,
    //     date: "24 February 2020",
    //     link: "/blogs/blog-post-layout-01",
    // },
    // {
    //     id: 146,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "Computers are to design as microwaves are to cooking",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 3,
    //     category: ["DECOR", "decor"],
    //     blogType: "masonry",
    //     likes: 28,
    //     comments: 52,
    //     date: "10 February 2020",
    //     link: "/blogs/blog-post-layout-01",
    // },
    // {
    //     id: 147,
    //     img: "https://via.placeholder.com/800x560",
    //     title: "Fashion and interior design are one and the same",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 3,
    //     category: ["DESIGN", "decor"],
    //     blogType: "masonry",
    //     likes: 28,
    //     comments: 52,
    //     date: "18 February 2020",
    //     link: "/blogs/blog-post-layout-01",
    // },
    // {
    //     id: 148,
    //     img: "https://via.placeholder.com/800x580",
    //     title: "Recognizing the need is the primary condition design",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 3,
    //     category: ["FASHION", "desinagency"],
    //     blogType: "masonry",
    //     likes: 28,
    //     comments: 52,
    //     date: "24 February 2020",
    //     link: "/blogs/blog-post-layout-01",
    // },
    // {
    //     id: 149,
    //     img: "https://via.placeholder.com/800x580",
    //     title: "Computers are to design as microwaves are to cooking",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 3,
    //     category: ["LIFESTYLE", "desinagency"],
    //     blogType: "masonry",
    //     likes: 28,
    //     comments: 40,
    //     date: "10 February 2020",
    //     link: "/blogs/blog-post-layout-02",
    // },
    // {
    //     id: 150,
    //     img: "https://via.placeholder.com/800x580",
    //     title: "Fashion and interior design are one and the same",
    //     content: "Lorem ipsum is simply dummy text of printing and typesetting industry lorem ipsum been dummy...",
    //     author: 3,
    //     category: ["DESIGN", "desinagency"],
    //     blogType: "masonry",
    //     likes: 28,
    //     comments: 40,
    //     date: "18 January 2020",
    //     link: "/blogs/blog-post-layout-03",
    // },
    // {
    //     id: 151,
    //     category: ["Creativity", "creativeagency"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x800",
    //     title: "Architects design houses. I live in a home",
    //     content: "",
    //     author: 1,
    //     likes: 0,
    //     comments: 0,
    //     date: "30 AUGUST 2018"
    // },
    // {
    //     id: 152,
    //     category: ["Modern", "creativeagency"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x800",
    //     title: "Good design doesn't date. Bad design does",
    //     content: "",
    //     author: 1,
    //     likes: 0,
    //     comments: 0,
    //     date: "30 AUGUST 2018"
    // },
    // {
    //     id: 153,
    //     category: ["CONCEPT", "photography"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x800",
    //     title: "People ignore design that ignores people",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "10 SEPTEMBER 2019",
    //     double_col: false
    // },
    // {
    //     id: 154,
    //     category: ["DESIGN", "photography"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x800",
    //     title: "Everything designed things are designed",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "28 AUGUST 2019",
    //     double_col: false
    // },
    // {
    //     id: 155,
    //     category: ["LIFESTYLE", "photography"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x800",
    //     title: "Simplicity is the ultimate sophistication",
    //     content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been dummy text...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "22 JUNE 2019",
    //     double_col: false
    // },
    // {
    //     id: 156,
    //     category: ["fashion", "classicblog"],
    //     blogType: "clean",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x710",
    //     title: "Build perfect websites",
    //     content: "",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "23 FEBRUARY 2020",
    // },
    // {
    //     id: 157,
    //     category: ["fashion", "classicblog"],
    //     blogType: "clean",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x710",
    //     title: "Beautiful layouts design",
    //     content: "",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "18 FEBRUARY 2020",
    // },
    // {
    //     id: 158,
    //     category: ["fashion", "classicblog"],
    //     blogType: "clean",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x710",
    //     title: "Online website builder",
    //     content: "",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "10 JANUARY 2020",
    // },
    // {
    //     id: 159,
    //     category: ["fashion", "classicblog"],
    //     blogType: "clean",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x710",
    //     title: "Different layout type",
    //     content: "",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "30 DECEMBER 2019",
    // },
    // {
    //     id: 160,
    //     category: ["fashion", "classicblog"],
    //     blogType: "clean",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x710",
    //     title: "Elegant elements design",
    //     content: "",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "22 NOVEMBER 2019",
    // },
    // {
    //     id: 161,
    //     category: ["Architecture"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x1190",
    //     title: "Architects design houses. I live in a home",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "05 March 2020",
    //     double_col: false
    // },
    // {
    //     id: 162,
    //     category: ["Creativity"],
    //     blogType: "metro",
    //     tags: ["Development", "Events", "Media", "Mountains"],
    //     img: "https://via.placeholder.com/800x1190",
    //     title: "Good design doesn't date bad design does",
    //     content: "Lorem ipsum is simply dummy text printing typesetting industry lorem ipsum been dummy...",
    //     author: 3,
    //     likes: 25,
    //     comments: 0,
    //     date: "28 February 2020",
    //     double_col: false
    // },
]


export const BlogCategoryData = [
    {
        id: 118,
        category: "LIFESTYLE",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    },
    {
        id: 119,
        category: "SUMMER",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    },
    {
        id: 120,
        category: "COFFEE",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    },
    {
        id: 121,
        category: "FASHION",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    },
    {
        id: 122,
        category: "DRINKS",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    }
]

export const authorData = [
    {
        id: 2,
        img: "https://www.freelancermap.de/userimages/Thomas-FunkeMBA-492684-xxl_dpr2-1680689013.jpg",
        name: "Thomas Funke",
        description: "Thomas Funke has more than 10 years of professional experience in various functions in Big 4 companies and German family businesses.",
        designation: "PARTNER"
    },
    {
        id: 3,
        img: "https://via.placeholder.com/125x125",
        name: "Bill gardner",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 4,
        img: "https://via.placeholder.com/125x125",
        name: "Vico magistre",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 5,
        img: "https://via.placeholder.com/125x125",
        name: "Coco chanel",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 6,
        img: "https://via.placeholder.com/125x125",
        name: "Mark lamb",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 7,
        img: "https://via.placeholder.com/125x125",
        name: "Lindsey bucki",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 8,
        img: "https://via.placeholder.com/125x125",
        name: "Maya angelou",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 9,
        img: "https://via.placeholder.com/125x125",
        name: "Jeremy dupont",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
]

export const FilterData = [
    {
        title: "All",
        key: "*"
    },
    {
        title: "FASHION",
        key: "fashion "
    },
    {
        title: "TRAVEL",
        key: "travel "
    },
    {
        title: "LIFESTYLE",
        key: "lifestyle "
    },
    {
        title: "BUSINESS",
        key: "business"
    }
]
